'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');

var clearSelection = element => {
    var $currentPane = $(element).closest('.custom-dropdown.show');

    $currentPane.removeClass('show');

    // Adjust DOM after drawer finishes closing (mobile) or dropdown finishes hiding (desktop)
    setTimeout(() => {
        $currentPane.find('.nav-link').attr('aria-expanded', 'false');
        $currentPane.find('.dropdown-menu').attr('aria-hidden', 'true');
        $currentPane.find('.top-category').detach();
        $currentPane.find('.nav-menu').detach();

        if (!$currentPane.closest('.custom-dropdown.show').length) {
            $currentPane.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
        }
    }, SiteConstants.TransitionSpeed);
};

var subCategoriesCollapse = collapse => { // Collapse fourth-level-menu on mobile
    var $dropdownHeaders = $('.dropdown-header');
    if (collapse) {
        var $dropdownSubCategories = $('.dropdown-subcategories');
        $dropdownHeaders
            .attr('data-toggle', 'collapse');
        $dropdownSubCategories
            .addClass('collapse');
        $('a.dropdown-header').each(function(){ // Set href to target subcategory menu
            var collapseBodyId = $(this).attr("data-loop-status");
            $(this).attr("href", '#' + collapseBodyId);
        });
    } else {
        $dropdownHeaders.removeAttr('data-toggle');
    }
}

var toggleDrawer = status => {
    var $header = $('header');
    var $headerBanner = $header.find('.header-banner');
    var $headerNav = $header.find('.header-nav');
    var headerNavHeight = !$headerBanner.hasClass('d-none') ? $headerNav.outerHeight() + $headerBanner.outerHeight() : $headerNav.outerHeight();
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        $('html, body').scrollTop($headerNav.offset().top);
        $('html, body').addClass('lock-scroll');
        $('body').addClass('mobile-menu-in');
        closeDropdowns();
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .css('top', headerNavHeight)
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed)
            .css('top', headerNavHeight);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();
        subCategoriesCollapse(true);
    } else {
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);
        subCategoriesCollapse(false);
        setTimeout(() => {
            $modalBackground.css('top', '');
            $('html, body').removeClass('lock-scroll');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

function megaMenuOpen(eventElement) {
    // Select dropdown based on current experience
    var $dropdowns = $('.navbar').hasClass('new-navbar') ? $('.nav-items > li') : $('.navbar-nav > li');
    // Close all dropdowns
    $dropdowns.each((index, element) => {
        if (!$.contains(element, eventElement)) {
            var $navItem = $(element);
            $navItem.find('.show').each(() => {
                clearSelection(element);
            });

            $navItem.removeClass('show');
            $navItem.children('ul.dropdown-menu').removeClass('show');
            $navItem.children('.nav-link').attr('aria-expanded', 'false');
        }
    });
    // Open current dropdown
    $(eventElement).parent().addClass('show');
    $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
    $(eventElement).attr('aria-expanded', 'true');
    // Reconstruct dropdown menu on desktop
    $('.dropdown-header').attr('data-toggle', 'dropdown');
    $('.dropdown-subcategories').removeClass('collapse');
    $('a.dropdown-header').each(function () {
        var categoryUrl = $(this).attr("data-url"); // Get stored category url
        $(this).attr("href", categoryUrl);
    });
}

function megaMenuClose() {
    $('.navbar-nav > li').each((index, element) => {
        var $navItem = $(element);
        $navItem.find('.show').each(() => {
            clearSelection(element);
        });

        $navItem.removeClass('show');
        $navItem.children('ul.dropdown-menu').removeClass('show');
        $navItem.children('.nav-link').attr('aria-expanded', 'false');
    });
}

function megaMenuLeft(el) {
    if (!window.isMobile()) {
        var menuName = el.id,
        menuRight = $('#' + menuName + '-right'),
        menuLeft = $(el).parent().parent(),
        menuRightParent = menuLeft.next();

        // clear classes
        menuLeft.find('.active').removeClass('active');
        menuRightParent.removeClass('has-sub-asset');
        menuRightParent.find('.active-list').removeClass('active-list');
        // add classes
        $(el).addClass('active');
        menuRight.parent().addClass('active-list');
        // add a class to the parent Ul if a third level asset is displayed; css-hides the top level asset
        if (menuRight.parent().next().hasClass('sub-asset-1') || menuRight.parent().next().hasClass('sub-asset-2')) {
            menuRightParent.addClass('has-sub-asset');
        }
    }
}

function closeDropdowns(){
    $('.dropdown-menu').each((index, element) => {
        if ($(element).hasClass('show')){
            $(element).removeClass('show');
        }
    });
}
module.exports = () => {

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-account-container .nav-link',
        {
            40: function(menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    // close megamenu on touchend events
    $('html').on('touchend', event => {
        if (!window.isMobile() && $(event.target).closest('.navbar-nav') == 0) {
            megaMenuClose();
        }
    });

    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('touchend click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        var isNewExperience = $('.navbar').hasClass('new-navbar');
        if (window.isMobile() && !$(event.target).is('#dropdownAccountSelector,#dropdownCountrySelector,#dropdownCountrySelectorMobile,.flag-icon')) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false')
                .addClass('mega-left-title');
            $li.append(link);

            $closeMenu.append($('.close-menu').first().clone());
            $closeMenu.find('.back-parent-name').empty().html(event.target.dataset.parentname);

            if (!$dropdown.hasClass('fourth-level-menu')) {
                $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
                $dropdown.addClass('show');
                $dropdownLink.attr('aria-expanded', 'true');
                $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                $(link).focus();
                $('.dropdown-menu').scrollTop(0);
            } else {
                link = $(event.target).attr('href');
                if (link) {
                    location.href = $(event.target).attr('href');
                }
            }
        } else if (event.type === 'touchend' && $(event.target).is('#dropdownAccountSelector,#dropdownCountrySelector,#dropdownCountrySelectorMobile')){
            if (!$(event.target).siblings('.dropdown-menu').hasClass('show')){
                closeDropdowns();
            }
            if (!$('.header-nav').hasClass('transparent-nav')){
                toggleDrawer('close');
            }
            $(event.target).siblings('.dropdown-menu').toggleClass('show');
        } else if (event.type === 'touchend') {
            if (!$(event.target).parent().hasClass('show')){
                event.preventDefault();
                megaMenuOpen(event.target);
            }
        } else {
            // Restore link behavior on desktop
            link = $(event.target).attr('href');
            if (link && !isNewExperience) {
                location.href = $(event.target).attr('href');
            }
        }
    });

    $('.mega-left-menu-link').on('touchend', function(event) {
        event.stopPropagation();
        if (!$(event.target).parent().hasClass('active')){
            event.preventDefault();
            megaMenuLeft(this);
        }
    });

    //Megamenu Mega-left menu
    $('.mega-left-menu-link').hoverIntent( function() {
        megaMenuLeft(this);
    }, 50);

    // Open Navigation on Click
    $dropdownMenu.on('click', function(e){
        if ($('.navbar').hasClass('new-navbar')) {
            megaMenuOpen(e.target);
        }
    });

    // When desktop header category link clicked, open category column
    $('.desktop-category-links .nav-link').on('click', function(e){
        if (!window.isMobile() && $('.navbar').hasClass('new-navbar')) {
            var clickedCategory = $(this).data('category');
            var $categoryInPopup = $('.nav-item a[data-category=' + clickedCategory + ']');

            if ($categoryInPopup.siblings('.dropdown-menu').length === 0) {
                // Directly navigate to category with no children.
                location.href = $categoryInPopup.attr('href');
            } else {
                // Open navbar if not open
                var navbarOpen = $('.main-menu').hasClass('in');
                if (!navbarOpen) {
                    $('.navbar-toggler').click();
                    // After opening navbar, focus into it
                    $('.navbar-nav .nav-items .dropdown-toggle').first().focus();
                }

                // Open intended category column
                $categoryInPopup.click();
            }
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile() && !$('.navbar').hasClass('new-navbar')) {
                megaMenuOpen(event.target);
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });

    // Desktop - close menu on mouseleave
    $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile() && !$('.navbar').hasClass('new-navbar')) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });

    // New Experience Desktop - Prevent Link Click on Desktop Menu Button
    $('.navbar.new-navbar .megamenu > .dropdown-toggle').on('click', function(event) {
        if (!window.isMobile()){
            event.preventDefault();
        }
    });

    // New Experience Desktop - Close Modal on ESC press
    $('body').on('keyup', function(event) {
        var isNewExperience = $('.navbar').hasClass('new-navbar');
        var isESCKeyPressed = event.keyCode === 27 ? true : false;
        var isNavbarOpen = $('.main-menu').hasClass('in');
        var isDesktop = !window.isMobile();
        if (isDesktop && isNewExperience && isESCKeyPressed && isNavbarOpen) {
            $('.navbar-toggler').click();
        }
    });
    
    // New Experience Mobile - Prevent Restore Link on Sub Categories
    $('.navbar.new-navbar .dropdown-item > .dropdown-toggle').on('click', function(event) {
        if (window.isMobile()){
            location.href = $(this).attr('href');
        }
    }).on('touchend', function(event){
        if (window.isMobile()){
            location.href = $(this).attr('href');
        }
    });

    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        clearSelection(event.target);
    });

    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
            $('.navbar-nav .nav-items .dropdown-toggle').first().focus();
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
};