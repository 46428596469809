'use strict';

$(document).ready(function () {
    if (needsGeoRedirectModal()) {
        function getAllParams(url) {
            var urlParams = new URLSearchParams(url.split('?')[1]);
            var params = {};
            
            urlParams.forEach(function(value, key) {
                if (value !== null && value.trim() !== '') {
                    params[key] = value;
                }
            });
            
            return params;
        }

        $.spinner().start();
        var geoContainer = $('.geo-redirect-container');
        var url = geoContainer.data('href');
        $.ajax({
            url: url,
            type: 'get',
            success: function (response) {
                geoContainer.html(response).show();
                $('.modal-background').show().addClass('geo-modal');
                $('#geoRedirectModal').on('click', 'button.geopopin-link', function () {
                    geoContainer.hide();
                    $('.modal-background').hide().removeClass('geo-modal');
                    var redirectUrl = $(this).data('url');
                    var currentUrl = window.location.href;
                    var urlParams = getAllParams(currentUrl);
                    if ($(this).data('iseswcountry') === true) {
                        $.ajax({
                            url: redirectUrl,
                            type: 'GET',
                            dataType: 'json',
                            success: function (data) {
                                var updatedUrl = $.param(urlParams) ? data.redirectUrl + '?' + $.param(urlParams) : data.redirectUrl;
                                window.location.href = updatedUrl;
                            }
                        });
                    } else {
                        window.location.href = redirectUrl;
                    }
                });
                $('#geoRedirectModal').on('click', '.close, .link-close', function () {
                    geoContainer.hide();
                    $('.modal-background').hide().removeClass('geo-modal');
                });
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    }
});

function needsGeoRedirectModal() {
    var cookies = document.cookie.split(';');
    var cookieValue = '';
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
        if (cookie.indexOf('WarnGeoRedirect=') == 0) {
            cookieValue = cookie.substring('WarnGeoRedirect='.length, cookie.length);
        }
    }

    return cookieValue === 'true';
}
