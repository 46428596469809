'use strict';

$(document).ready(function () {
    // If ApplePay is available show the radio button, etc.
    var applepayRadio = $('ul.applepay-radio');
    if (window.ApplePaySession) {
        applepayRadio.show();
    }

    // Replace the default Adyen CC image/label with the dynamic images from the hidden tab
    var target = $('#paymentMethodsList');

    if (target.length > 0) {
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                var newNodes = mutation.addedNodes;
                if(newNodes !== null) {
                    var $nodes = $(newNodes);
                    $nodes.each(function() {
                        // if this is the credit card li
                        var $radio = $(this).find('#rb_scheme');
                        if ($radio.length > 0) {
                            var html = $('a.adyen-tab').html();
                            $radio.after(html);
                        }
                    });
                }
            });
        });

        var config = {
            attributes: true,
            childList: true,
            characterData: true
        };

        observer.observe(target[0], config);
    }

    $('body').on('click', '.adyen-component-content li.paymentMethod', function () {
        $('.adyen-component-content, .adyen-tab').addClass('active');
        $('.applepay-content, .applepay-tab, .paypal-content, .paypal-tab').removeClass('active');

        $('#paypalRadio, #applepayRadio').prop('checked', false);

        var $radio = $(this).find('#rb_scheme');
        if ($radio.length > 0) {
            $radio.prop('checked', true);
        }

        if ($('input[name="brandCode"]:checked').val() === 'scheme' || $('input[name="brandCode"]:checked').val() === 'ideal' || ($(this).find('input[name*="brandCode"]') && $(this).find('input[name*="brandCode"]').val().indexOf('storedCard') >= 0)) {
            $(this).find('.additionalFields').show();
        } else {
            $('.tab-pane .additionalFields').hide();
        }

        $('input[name*="billing_paymentMethod"]').val('AdyenComponent');
        $('.submit-payment').show();
    });

    $('body').on('click', 'a.paypalRadio', function (e) {
        e.preventDefault();

        $('.paypal-content, .paypal-tab').addClass('active');
        $('.adyen-component-content, .adyen-tab, .applepay-content, .applepay-tab').removeClass('active');

        setTimeout(function () {
            $('#paypalRadio').prop('checked', true);
        }, 100);
        $('input[name="brandCode"], #applepayRadio').prop('checked', false);

        $('.tab-pane .additionalFields').hide();

        $('input[name*="billing_paymentMethod"]').val('PayPal');

        var selectedAttr = $("#sessionPaypalAccount").attr("selected");
        if (typeof selectedAttr === "undefined") {
            $('.submit-payment').hide();
          }
    });

    $('body').on('click', 'a.applepayRadio', function (e) {
        e.preventDefault();

        $('.applepay-content, .applepay-tab').addClass('active');
        $('.adyen-component-content, .adyen-tab, .paypal-content, .paypal-tab').removeClass('active');

        setTimeout(function () {
            $('#applepayRadio').prop('checked', true);
        }, 100);
        $('input[name="brandCode"], #paypalRadio').prop('checked', false);

        $('.tab-pane .additionalFields').hide();

        $('input[name*="billing_paymentMethod"]').val('DW_APPLE_PAY');
        $('.submit-payment').hide();
    });
});
