var processInclude = require('base/util');

// This list should include any JS files that check for specific nodes in DOM that may be added to belowFold region
var coreFiles = {
    einstein: require('core/components/einsteinRecs'),
    productTile: require('core/components/productTile'),
    video: require('core/components/video'),
    slider: require('core/components/slider').init,
    pageDesigner: require('core/pageDesigner')
}

$(document).ready(() => {
    // Render any components in the Below Fold region via ajax after page load
    const $belowFoldRegions = $('.belowFoldRegion').not('[data-processed="true"]');

    if ($belowFoldRegions.length) {

        $belowFoldRegions.each(function() {
            const $this = $(this);

            $this.attr('data-processed', 'true');

            new Promise(resolve => {

                // Send to Page-Show, return HTML for ajaxRegion template
                $.ajax({
                    url: $this.data('url'),
                    method: 'GET',
                    success: response => {
                        $this.html(response);
                        resolve();
                    },
                    error: err => {
                        console.error('Unable to render Below Fold region ', err);
                    }
                });

            }).then(() => {
                Object.keys(coreFiles).forEach(function (key) {
                    processInclude(coreFiles[key]);
                });
            });

        });

    }

});
