'use strict';

// enhanced sticky header fix
$(document).on("scroll", function() {
    var scrollPosition = $(document).scrollTop();
    var headerHeight = $("#header-nav").height();

    if (scrollPosition < headerHeight){
        $(".sticky-header .fixed-header-enhanced .header-nav").addClass("header-near-top");
    } else {
        $(".sticky-header .fixed-header-enhanced .header-nav").removeClass("header-near-top");
    }
});