'use strict';

var cart = require('./cart/cart');
var processInclude = require('base/util');

var baseFiles = {
    cart: function () {
        return cart.init.call(cart);
    }
};

module.exports = {
    baseFiles: baseFiles,
    loadFunctions: function () {
        Object.keys(baseFiles).forEach(function (key) {
            processInclude(baseFiles[key]);
        });
    },
    cartUpdateError: function() {
        // Remove cart error message when cart is valid.
        $('body').on('cart:update', function (e,data) {
            if (!data.cartModel?.valid.error) {
                $('.alert.valid-cart-error').remove();
            }
        });
    }
};
