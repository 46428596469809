'use strict';
var core = require('core/product/detail');

core.ratingsScroll = function() {
    $(".ratings-link").click(event => {
        if ($(event.target).attr("href").length > 0) {
            $("html, body").animate({ scrollTop: $($(event.target).attr("href")).offset().top }, 500);
        }
    })
};

core.videoScoll = function() {
    $('body').on('click', '.video-play-button', function(e) {
        $('.primary-images-main').animate({ scrollLeft: $('.primary-images video').offset().left - 24 }, 500);
    })
};

core.reviewFields = function () {
    $("body").on("click", ".product-related-fields-see-more", () => {
        let $reviewFieldsParent = $(event.target).closest(".aggregated-product-related-fields");
        let layoutWrapper = ".product-related-fields-desktop-layout";
        if ($reviewFieldsParent.children("layoutWrapper").is(":hidden")) {
            layoutWrapper = ".product-related-fields-mobile-layout";
        }
        let $columnWrapper = $reviewFieldsParent.find(layoutWrapper + ' .product-related-fields-column');

        $columnWrapper.not(":last").children().odd().addClass("custom-review-fields-move-down-bottom");
        $columnWrapper.eq(1).addClass("custom-review-fields-move-up-second");
        $columnWrapper.eq(2).addClass("custom-review-fields-move-up-first");
    });

    $("body").on("click", ".product-related-fields-see-less", () => {
        let $reviewFieldsParent = $(event.target).closest(".aggregated-product-related-fields");
        let layoutWrapper = ".product-related-fields-desktop-layout";
        if ($reviewFieldsParent.children("layoutWrapper").is(":hidden")) {
            layoutWrapper = ".product-related-fields-mobile-layout";
        }
        let $columnWrapper = $reviewFieldsParent.find(layoutWrapper + ' .product-related-fields-column');

        $columnWrapper.removeClass(["custom-review-fields-move-up-second", "custom-review-fields-move-up-first"]);
        $columnWrapper.children().removeClass("custom-review-fields-move-down-bottom");
    });
    // $(".product-related-fields-see-more").click(event => {
    //     let $reviewFieldsParent = $(event.target).closest(".aggregated-product-related-fields");
    //     let $columnWrapper = $reviewFieldsParent.find('.product-related-fields-desktop-layout .product-related-fields-column');

    //     $columnWrapper.not(":last").children().odd().addClass("custom-review-fields-move-down-bottom");
    //     $columnWrapper.eq(1).addClass("custom-review-fields-move-up-second");
    //     $columnWrapper.eq(2).addClass("custom-review-fields-move-up-first");
    // })
    // $(".product-related-fields-see-less").click(event => {
    //     let $reviewFieldsParent = $(event.target).closest(".aggregated-product-related-fields");
    //     let $columnWrapper = $reviewFieldsParent.find('.product-related-fields-desktop-layout .product-related-fields-column');
    //     $columnWrapper.removeClass(["custom-review-fields-move-up-second", "custom-review-fields-move-up-first"]);

    //     $columnWrapper.children().removeClass("custom-review-fields-move-down-bottom");
    // })
}

core.selectSizeCTA = function() {
    $('body').on('click', 'button.select-size', function(e){
        let $productContainer = $(e.target).closest('.product-detail');
        $productContainer.find('.size-error').removeClass('d-none');
    });

    $('body').on('click', '.size-value:not(.unavailable)', function(e){
        let $productContainer = $(e.target).closest('.product-detail');
        let $addToCart = $productContainer.find('.add-to-cart, .add-to-cart-global');
        if ($addToCart.hasClass('d-none')){
            $addToCart.removeClass('d-none');
            $addToCart.text($addToCart.attr('data-in-stock-message'));
            $productContainer.find('.apple-pay-container').removeClass('d-none');
            $productContainer.find('button.select-size').addClass('d-none');
        }
        if (!$productContainer.find('.size-error').hasClass('d-none')){
            $productContainer.find('.size-error').addClass('d-none');
        }
    });

    $(document).ready(function(){
        if (!$('button.select-size').hasClass('d-none')){
            setNoSize();
            if ($('.stock-indicator').hasClass('visible')){
                $('.stock-indicator').removeClass('visible');
            }
            $('.js-bis-container').addClass('d-none');
        }

        if ($('.size-value').length == 1 && $('.size-value.selected').length == 0){
            $('.size-value').trigger('click');
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, passedObj) {
        let $productContainer = passedObj.container;
        let $addToCart = $productContainer.find('.add-to-cart, .add-to-cart-global');
        if (passedObj.isColorAttributeClicked) {
            $('.js-bis-container').addClass('d-none');

            if (!$addToCart.hasClass('d-none')) {
                $addToCart.addClass('d-none');
                $productContainer.find('.apple-pay-container').addClass('d-none');
                $productContainer.find('button.select-size').removeClass('d-none');
            }
        }

        if (passedObj.data.product.availability.outOfStock && !passedObj.isColorAttributeClicked) {
            $addToCart.removeClass('d-none');
            $addToCart.text(passedObj.data.product.availability.stockMessage);
        } else {
            $addToCart.text($('button.add-to-cart').data('in-stock-message'));
        }

        // Check if bundle and handle bundle swatch select behavior
        if ($('.bundle-items .bundle-item').length > 0) {
            var $currentBundleItem = passedObj.container;
            // If all available variation attributes are selected
            var attrCount = passedObj.container.find('.attributes .attribute').length;
            if (attrCount <= passedObj.container.find('.attributes .attribute .selected').length) {
                // Set checkmark, close current item, open the next unselected and scroll to item
                $currentBundleItem.siblings('.accordion-header').find('.checkmark').addClass('active');
                $currentBundleItem.closest('.accordion-item').addClass('selected');
                $currentBundleItem.siblings('.accordion-header').find('.checkmark').click();

                var $nextBundleItem = $('.accordion-item').not('.selected').first();
                if ($nextBundleItem.length !== 0) {
                    setTimeout(function() {
                        $('html, body').animate({ scrollTop: $nextBundleItem.offset().top }, 500);
                    }, 500);
                    
                    $nextBundleItem.find('.checkmark').click();
                }
            }
        }

        if ($('.add-to-cart-global').hasClass('d-none')){
            // Check all sizes selected
            if ($('.bundle-item .swatch.size-value.available.selected').length === $('.bundle-item').length) {
                $('.add-to-cart-global').removeClass('d-none');
                $('.apple-pay-container').removeClass('d-none');
                $('button.select-size').addClass('d-none');
            }
        }
    });
};

function setNoSize(){
    if (!$('button.select-size').hasClass('d-none')){
        $('.color-attribute').each(function(){
            $(this).attr('data-url',$(this).attr('data-url') + '&noSize=true');
        });
    }
}

module.exports = core;
