'use strict';
var core = require('core/cart/cart');
var SiteConstants = require('constants/SiteConstants');

/**
 * override rvw_autobahn_core
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts, allShippingPriceAdjustments) {
    var index = 0;

    // Update templates if discount # changes
    var totalDiscounts = approachingDiscounts.length + allShippingPriceAdjustments.length;
    var totalDiscountsDisplayed = $(".approaching-discounts").first().children(".single-approaching-discount").length;
    if (totalDiscounts !== totalDiscountsDisplayed){
        while (totalDiscountsDisplayed < totalDiscounts){
            $(".approaching-discounts").each(function(i) {
                var element = $(".approaching-discounts")[i];
                $(element).append(
                    "<div class='single-approaching-discount'>"
                    +   "<div class='threshold-message text-center'>"
                    +   "</div>"
                    +   "<div class='threshold-bar'>"
                    +       "<div class='threshold-bar-progress'></div>"
                    +   "</div>"
                    +   "<div class='threshold-met text-center'></div>"
                    +"</div>"
                );
            });
            totalDiscounts = approachingDiscounts.length + allShippingPriceAdjustments.length;
            totalDiscountsDisplayed = $(".approaching-discounts").first().children(".single-approaching-discount").length;
        }
        while (totalDiscountsDisplayed > totalDiscounts){
            $(".approaching-discounts").each(function(i) {
                var element = $(".approaching-discounts")[i];
                var extraItem = $(element).children(".single-approaching-discount").last();
                $(extraItem).remove();
            });
            totalDiscounts = approachingDiscounts.length + allShippingPriceAdjustments.length;
            totalDiscountsDisplayed = $(".approaching-discounts").first().children(".single-approaching-discount").length;
        }
    }
    
    // Loop through approaching discounts and update
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            var discountCount = $('.single-approaching-discount').length;
            // Update Mobile
            var discountContainer = $('.single-approaching-discount').get(index);
            updateDiscountContent(discountContainer);
            // Update Desktop
            discountContainer = $('.single-approaching-discount').get(discountCount / 2 + index);
            updateDiscountContent(discountContainer);

            function updateDiscountContent(discountContainer){
                var message = $(discountContainer).children('.threshold-message');
                var bar = $(discountContainer).children('.threshold-bar');
                var percent = $(bar).children('.threshold-bar-progress');
                var met = $(discountContainer).children('.threshold-met');
    
                $(message).text(item.discountMsg);
                $(message).removeClass('d-none');
                $(bar).removeClass('d-none');
                $(percent).data('thresholdPercentage', item.percentage);
                var percentValue = $(percent).data('thresholdPercentage');
                $(percent).width(percentValue + '%');
                $(percent).removeClass('d-none');
                $(met).addClass('d-none');
            }

            index++;
        });
    } 
    // Loop through shipping adjustments and update
    if (allShippingPriceAdjustments.length > 0){
        allShippingPriceAdjustments.forEach(function (item) {
            var discountCount = $('.single-approaching-discount').length;
            // Update Mobile
            var discountContainer = $('.single-approaching-discount').get(index);
            update(discountContainer);
            // Update Desktop
            discountContainer = $('.single-approaching-discount').get(discountCount / 2 + index);
            update(discountContainer);

            function update(discountContainer){
                var message = $(discountContainer).children('.threshold-message');
                var bar = $(discountContainer).children('.threshold-bar');
                var met = $(discountContainer).children('.threshold-met');

                $(message).addClass('d-none');
                $(bar).addClass('d-none');
                $(met).text(item.calloutMsg);
                $(met).removeClass('d-none');
            }

            index++;
        });
    }
    // Hide Discount Container when none active
    if (totalDiscounts === 0){
        $(".approaching-discounts").each(function(i) {
            var element = $(".approaching-discounts")[i];
            var border = $(element).siblings(".discount-top-border");
            $(element).addClass('d-none');
            $(border).removeClass('d-none');
        });
    } else {
        $(".approaching-discounts").each(function(i) {
            var element = $(".approaching-discounts")[i];
            var border = $(element).siblings(".discount-top-border");
            $(element).removeClass('d-none');
            $(border).addClass('d-none');
        });
    }
}

/* 
 * Bethany's Note: ECOMSUP-144: switched all instances of module.exports to core.[function] when init() was moved up from core
 */

core.init = function() {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        core.confirmDelete(actionUrl, productID, productName, uuid); 
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        core.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = core.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.minicart-footer').empty();
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    // -- START -- ECOMSUP-144: changing the target class for clearing out the minicart
                    $('.minicart .minicart-pd').empty();
                    $('.minicart .minicart-pd').removeClass('show').addClass('hide');
                    $('.modal-background').fadeOut(SiteConstants.TransitionSpeed);
                    $('html, body').removeClass('lock-scroll');
                    // -- END -- 
                    $('body').removeClass('minicart-open');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    $('.minicart-backdrop').addClass('d-none');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    core.updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts, data.basket.allShippingPriceAdjustments);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    core.validateBasket(data.basket);
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var $select = $stepper.closest('.quantity-form').find('.quantity');
        var value = parseInt($stepper.find('input').val());

        $select.find('option[value="' + value + '"]').prop('selected', true).change();
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = core.appendToUrl(url, urlParams);

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                core.updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts, data.allShippingPriceAdjustments);
                core.updateAvailability(data, uuid);
                core.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $.spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    core.updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts, data.allShippingPriceAdjustments);
                    core.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    core.updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts, data.allShippingPriceAdjustments);
                    core.validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    core.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = core.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                core.updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts, data.allShippingPriceAdjustments);
                core.validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    core.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function (e) {
        if ($(".modal#chooseBonusProductModal").length === 0) {
            $.spinner().start();
            $(this).addClass('launched-modal');
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('bonusproduct:edit', data);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option-value-id', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        form.pid = core.getPidValue($(this))

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#quickViewModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    core.updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts, data.cartModel.allShippingPriceAdjustments);
                    core.updateAvailability(data.cartModel, form.uuid);
                    core.updateProductDetails(data, form.uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    core.validateBasket(data.cartModel);

                    $('body').trigger('cart:update', [data, form.uuid]);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        core.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });


    document.body.addEventListener("yotpoLoyaltyDiscountApplied", function(e) {
        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');

        // Get sfcc code
        var url = $('.yotpo-apply-coupon').attr('data-url');
        var data = {
            coupon: e.detail.discountCode
        }

        $.ajax({
            url: url,
            data: data,
            method: 'post',
            success: function(data) {
                $.spinner().stop();
                core.updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts, data.allShippingPriceAdjustments);
                core.validateBasket(data);
                $('body').trigger('promotion:success', data);
            }
        });

    });
}

module.exports = core;
