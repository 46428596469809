'use strict';

/**
 * On page ready, update Primary Image DOM
 * Images need a wrapper on desktop to support zoom
 * Function adds/removes the wrapper on images based on current window size
 */
$('body .primary-images-main img').ready(function (){
    function updatePrimaryImageWrapper(){
        var wrappedImagesPresent = $('body .primary-images-main .image-wrapper img').length > 0 ? true : false;
        var noimagePresent = ($('body .primary-images-main img').length === 1 && $('body .primary-images-main img').attr('src').indexOf('noimage-') !== -1) ? true : false;
        var isQuickView = $('body').find('.modal.show .product-quickview').length !== 0;

        if (!noimagePresent && !window.isMobile() && !wrappedImagesPresent && !isQuickView){
            var $images = $('body .primary-images-main img');
            $($images).each(function() {
                var $image = $(this);
                $($image).wrap('<div class="image-wrapper zoomed-out"/>');
            });
        } else if (!noimagePresent && window.isMobile() && wrappedImagesPresent && !isQuickView) {
            var $images = $('body .primary-images-main .image-wrapper img');
            $($images).each(function() {
                var $image = $(this);
                $($image).unwrap();
            });
        } else if (noimagePresent){
            $('body .primary-images-main img').unwrap();
        }
    }

    $(window).on("resize", function() {
        updatePrimaryImageWrapper();
    });
    updatePrimaryImageWrapper();
});

/**
 * On click, toggle zoomed-in/zoomed-out classes on image-wrapper
 * Classes will change cursor to (-/+) SVG
 * Adds listeners that update image zoom placement when zoomed-in
 * 
 * @param {Jquery Event} e Used for determining cursor position
 */
$('body').on('click', '.primary-images-main .image-wrapper img', function(e) {
    var $image = $(this);
    var src = $($image).attr('src');

    /*
        TODO: AFTER FOX MOVES FROM SCENE7 TO WIDEN
        REMOVE LOGIC FOR: isNewImageHost 
        REPLACE WITH:
        var srcSubstr = '?w=1500&h=1500';
    */

    var isNewImageHost = !src.includes("scene7");
    if (isNewImageHost){
        // Widen URL Parameters - Used by Bell and Giro
        var srcSubstr = '?w=1500&h=1500';
    } else {
        // Scene7 URL Parameters - Used by Fox
        var srcSubstr = '?$dw_detn1$&wid=1500&hei=1500&fmt=webp-alpha';
    }

    if (src.indexOf(srcSubstr) === -1){
        var newsrc = src.split('?')[0] + srcSubstr;
        $($image).attr('src', newsrc);
    }
    
    var $imageWrapper = $(this).parent();
    $($imageWrapper).toggleClass('zoomed-out zoomed-in');
    if ($($imageWrapper).hasClass('zoomed-in')){
        updateImagePosition(e, $image);
        $('body').on('mouseover', '.primary-images-main .image-wrapper.zoomed-in', function(eOver) {
            updateImagePosition(eOver, $image);
        });
        $('body').on('mousemove', '.primary-images-main .image-wrapper.zoomed-in', function(eOut) {
            updateImagePosition(eOut, $image);
        });
    } else if ($($imageWrapper).hasClass('zoomed-out')){
        returnToDefaultState($image);
    }
});

/**
 * Updates clicked image zoom based on current cursor position relative to image
 * 
 * @param {Jquery Event} e Used for determining cursor position
 * @param {Jquery Image Object} image Current clicked image
 */
function updateImagePosition(e, image){
    var x = e.pageX - $(e.currentTarget).offset().left;
    var y = e.pageY - $(e.currentTarget).offset().top;
    $(image).css('transform', 'scale(2.5)');
    $(image).css('transformOrigin', `${x}px ${y}px`);
}

/**
 * Removes listeners on clicked image and removes zoom
 * 
 * @param {Jquery Image Object} image Current clicked image
 */
function returnToDefaultState(image){
    $(image).css('transform', 'scale(1)');
    $('body').off('mousemove', '.primary-images-main .image-wrapper.zoomed-in');
    $('body').off('mouseover', '.primary-images-main .image-wrapper.zoomed-in');
}

/**
 * Removes zoomed-in class when cursor leaves image
 * Triggers returnToDefaultState() to unzoom image
 */
$('body').on('mouseleave', '.primary-images-main .image-wrapper img', function() {
    var $image = $(this);
    var $imageWrapper = $(this).parent();
    $($imageWrapper).removeClass('zoomed-in');
    $($imageWrapper).addClass('zoomed-out');
    returnToDefaultState($image);
});