'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
const $body = $('body');
module.exports = function () {

    keyboardAccessibility('.navbar-header .country-selector',
        {
            40: function ($countryOptions) { // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus').next().focus();
                }
            },
            38: function ($countryOptions) { // up
                if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                    $(this).focus();
                    $(this).removeClass('show');
                } else {
                    $(':focus').prev().focus();
                }
            },
            27: function () { // escape
                $(this).focus();
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            },
            9: function () { // tab
                $(this).removeClass('show').children('.dropdown-menu').removeClass('show');
            }
        },
        function () {
            if (!($(this).hasClass('show'))) {
                $(this).addClass('show');
            }
            return $(this).find('.dropdown-country-selector').children('a');
        }
    );

    if (window.UncachedData && window.UncachedData.locale == 'en_CA') {
        $('input[name$=_postalCode]').keyup(function() {
            var zipcode = $(this).val().split("-").join(""); // remove hyphens
            if (zipcode.length > 0 && zipcode.indexOf(' ') == -1) {
                zipcode = zipcode.match(new RegExp('.{1,3}', 'g')).join(" ");
            }
            $(this).val(zipcode);
        });
    }

    $('.js-country-selector').on('click', function (e) {

        e.preventDefault();

        showModal($(this).data('url'));
    });


    /**
     * Make AJAX call to url and display Modal
     * Adds Close Event Listeners to Modal, default behavior overridden
     * @param {string} url 
     * @returns {*}
     */
    function showModal(url) {
        $.spinner().start();
        
        return $.ajax(url)
            .then((data) => {
                const $modal = $(data.html);
                $body.append($modal);

                // Animate Modal Close
                $modal.on('mousedown', function(e) {
                    // Backdrop or Close Button clicked
                    if (e.target === this || $(e.target).hasClass('close')) {
                        $(e.target).on('mouseup', function(){
                            animateModalClose($modal);
                        });
                    }
                })
                .on('keydown', function(e) {
                    // ESC Key pressed
                    if (e.key === 'Escape') {
                        animateModalClose($modal);
                    }
                });

                // delete myself after im closed.
                $modal.on('hidden.bs.modal', function () {
                    $modal.remove();
                    $body.removeClass('modal-open');
                })
                .on('shown.bs.modal', function () {
                    $body.addClass('modal-open');
                });

                // init click event on alternate login/register button at bottom of modal
                $modal.on('click', '.js-country-selector', function (e) {
                    e.preventDefault();

                    // Update the existing modal with new data
                    updateModal($(this).data('url'), $modal);
                })

                // show modal
                $modal.modal('show');

                $('input[name="country"]').on('change', function() {
                    var eswCountryCode = $(this).data('esw-country-code');
                    var redirectUrl = $(this).data('redirect-url');
                    var eswCountry = eswCountryCode ? true : false;
                    var currentUrl = window.location.href;
                    var urlParams = getAllParams(currentUrl);
                    var queryString = $.param({
                        countryCode: eswCountryCode
                    });

                    var params = new URLSearchParams(redirectUrl);

                    var isEswRedirect = params.get('isEswRedirect');
                    if (eswCountry === true && !isEswRedirect) {
                        $.ajax({
                            url: redirectUrl + '?' + queryString,
                            type: 'GET',
                            dataType: 'json',
                            success: function (data) {
                                var updatedUrl = $.param(urlParams) ? data.redirectUrl + '?' + $.param(urlParams) : data.redirectUrl;
                                window.location.href = updatedUrl;
                            }
                        });
                    } else {
                        var updatedUrl =  $.param(urlParams) ? redirectUrl + '?' + $.param(urlParams) : redirectUrl; redirectUrl;
                        window.location.href = updatedUrl
                    }
                });
                    $.spinner().stop();
            });
        }

        function getUrlParameter(name) {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        }

        function getAllParams(url) {
            var urlParams = new URLSearchParams(url.split('?')[1]);
            var params = {};
            
            urlParams.forEach(function(value, key) {
                if (value !== null && value.trim() !== '' && key !== 'eswCountryCode' && key !== 'isEswRedirect') {
                    params[key] = value; 
                }
            });
            
            return params;
        }
    /**
     * Update Content of current visible Modal
     * Make AJAX call to url and replace $modal body with response HTML
     * @param {string} url
     * @param {jQuery} $modal
     * @returns {*}
     */
    function updateModal(url, $modal) {
        $.spinner().start();
        return $.ajax({
            url: url,
            data: {
                'modalShown': true
            }
        }).then((data) => {
            // Update Modal
            $modal.attr('id', data.modalID);
            $modal.find('.header-text').text(data.modalHeader);
            $modal.find('.modal-body').html(data.html);
            // Focus Modal so ESC close listener works
            $modal.trigger('focus');


            $.spinner().stop();
        });
    }

    /** 
     * Override Modal close behavior
     * Set right position to animate. Delay removing modal until after animation completes.
     * Reload page when closing modal after successful account creation.
     * @param {jQuery} $modal The modal DOM Element
     */
    function animateModalClose($modal) {
        var modalWidth = $modal.find('.modal-content').width();
        var timeoutDuration = $modal.find('.modal-dialog').css('transition-duration').slice(0, -1) * 1000;
        $modal.find('.modal-dialog').css('right', -modalWidth);
        setTimeout(function() {
            $modal.siblings('.modal-backdrop').remove();
            $modal.modal('hide');
            $modal.remove();
        }, timeoutDuration);

        if ($modal.attr('id') === 'registerSuccessModal'){
            location.reload();
        }
    }

    $(document).ready(function() {
        function getUrlParameter(name) {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        }
        var eswCountryCode = getUrlParameter('eswCountryCode');
        var isEswRedirect = getUrlParameter('isEswRedirect');

        if (eswCountryCode && isEswRedirect) {
            var currentUrl = window.location.href;
            var urlParams = getAllParams(currentUrl);

            var redirectUrl = currentUrl.split('?')[0] + 'countryselector';

            var queryString = $.param({
                countryCode: eswCountryCode
            });

            $.ajax({
                url: redirectUrl + '?' + queryString,
                method: 'GET',
                data: {
                    eswCountryCode: eswCountryCode,
                    isEswRedirect: isEswRedirect
                },
                success: function(data) {
                    var updatedUrl = $.param(urlParams) ? data.redirectUrl + '?' + $.param(urlParams) : data.redirectUrl;
                    window.location.href = updatedUrl;
                }
            });
        }
    });
};
