'use strict';

module.exports = function() {
    $('body').on('click', '.loyalty-opt-in', function(e) {
        var url = $(this).attr('data-url');
        var data = {
            optin: true
        }

        $.ajax({
            url: url,
            type: 'post',
            data: data,
            success: function(resp) {
                location.reload();
            }
        })
    });
}