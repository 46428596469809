'use strict';

// Grab the integrations in autobahn_core
var integrationsCore = require('integrations/product/base');
var modal = require('core/components/modal');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

//Attributes that display as non-color swatches
integrationsCore.nonColorAttribute = function () {
    $(document).on('click', 'button.swatch', function (e) {
        e.preventDefault();
        if (integrationsCore.methods.checkForClickableAttribute($(this))) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            var $productContainer = $(this).closest('.bundle-item');
        }
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        // URL handling to pass attributes
        var url = $(this).attr('data-url');
        if ($(this).closest('.bonus-product-item').length > 0) {
            url = appendToUrl(url, {bonus: true});
        }

        integrationsCore.methods.attributeSelect(url, $productContainer, false); // third argument indicates whether this is from user clicking a color attribute or not

        $productContainer.find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'));
    });
};

//Attributes that display as color swatches
integrationsCore.colorAttribute = function () {
    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();

        if (integrationsCore.methods.checkForClickableAttribute($(this))) {
            return;
        }

        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            var $productContainer = $(this).closest('.bundle-item');
        }
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        var url = $(this).attr('data-url');
        if ($(this).closest('.bonus-product-item').length > 0) {
            url = appendToUrl(url, {bonus: true});
        }

        integrationsCore.methods.attributeSelect(url, $productContainer, true); // third argument indicates whether this is from clicking a color attribute or not

        $productContainer.find('.color-display-value').text($(this).find('.swatch').data('displayvalue'));
    });
};

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
 integrationsCore.methods.getPidValue = function ($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
};

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.selectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {boolean} attr.values.available - Flag to indicate OOS status
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
integrationsCore.methods.processSwatchValues = function (attr, $productContainer, msgs, msg) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        var isQuickViewModal = $($productContainer).hasClass('product-quickview');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        // reset selected status of all sizes attribute when user is switching color
        // This removes the size selected class
        if (msgs.isColorAttributeClicked) {
            var isCartPage = $('.cart-page').length > 0;
            if ((attr.isOnlyVariant || attr.attributeId != 'color') && !isCartPage) {
                $attrValue.removeClass('selected');
                $attrValue.siblings('.selected-assistive-text').empty();
                $attrValue.removeAttr('selected');
            }
        }

        if (attr.isOnlyVariant || attr.attributeId != 'color') {
            if (attrValue.url) {
                $swatchButton.attr('data-url', attrValue.url);
            } else {
                $swatchButton.removeAttr('data-url');
            }

            // Set selectable and available states (logic handled by site/product config and product model)
            $attrValue.attr('value', attrValue.url).removeAttr('disabled');
            if (!attrValue.selectable || (!attrValue.available && msg.isBundle)) {
                if (!attr.isOnlyVariant && attr.attributeId == 'size') {
                    $attrValue.attr('disabled', true);
                    $attrValue.removeClass('selectable unselectable');
                    $attrValue.addClass('unselectable');
                }
            } else {
                $attrValue.removeClass('selectable unselectable');
                $attrValue.addClass('selectable');
            }

            if (attrValue.available) {
                $attrValue.removeClass('unavailable');
                $attrValue.addClass('available');
            } else {
                $attrValue.removeClass('available');
                $attrValue.addClass('unavailable');
            }

            if (attrValue.bisEnabled && !msg.isBundle && msg.isBonusProduct !== true && !isQuickViewModal) {
                $attrValue.addClass('bis-enabled');
            } else {
                $attrValue.removeClass('bis-enabled');
            }
        }

        if (attr.attributeId == 'size' && attrValue.selected == true) {
            $productContainer.find('.non-color-display-value').text(attrValue.displayValue);
        };
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
integrationsCore.methods.handlePostCartAdd = function (response) {
    // Here you can take over the handlePostCartAdd function in our client overlay
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    if ($('.minicart-backdrop').length === 0) {
        $('.minicart').prepend("<div class='minicart-backdrop d-none'/>");
    }


    if (response.error) {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    } else {
        const url = $('.minicart').data('action-url');
        const $minicart = $('.minicart .popover');

        $minicart.addClass('show');
        $(document.body).addClass('minicart-open');
        $('html, body').addClass('lock-scroll');
        setTimeout(function () {
            $.spinner().start();
        }, 50);

        // prevent loading script more than once
        var isPaypalLoaded = $('script#paypalScript[data-loaded="true"]').length > 0;
        if (!isPaypalLoaded) {
            // load paypal scripts
            var ppScripts = document.querySelectorAll('script#paypalScript');

            ppScripts.forEach((ppS) => {
                var script = document.createElement("script");
                script.src = ppS.dataset.src
                script.type = "text/javascript";
                script.dataset.loaded = true;
                ppS.parentNode.replaceChild(script, ppS);
            });
        }

        $.get(url, data => {
            $minicart.empty();
            $minicart.append(data);
            $.spinner().stop();
            $('.minicart-backdrop').removeClass('d-none');
            $('body').trigger('minicart:loaded', $minicart);
        });

        const minicartHide = setTimeout(function () {
            $minicart.removeClass('show');
            $(document.body).removeClass('minicart-open');
            $('.minicart-backdrop').addClass('d-none');
            $('html, body').removeClass('lock-scroll');
        }, 6000);

        $minicart.one("mouseenter touchstart", function () {
            clearTimeout(minicartHide);
        });

        $('.minicart-backdrop').off('click').on('click', function () {
            clearTimeout(minicartHide);
        })
    }

};

/**
 * Overwrites the method from core cartridge.
 */
integrationsCore.updateAddToCart = function() {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;
        // update local add to cart (for sets)
        $productContainer.find('button.add-to-cart').attr('disabled',
            (!response.product.readyToOrder || !response.product.available));
        // update global add to cart (single products, bundles)
        var $dialog = $productContainer.closest('.quick-view-dialog');
        if ($dialog.length){
            $dialog.find('.add-to-cart-global, .update-cart-product-global').attr('disabled',
                !$dialog.find('.global-availability').data('ready-to-order')
                || !$dialog.find('.global-availability').data('available')
            );
        } else {
            var enable = $productContainer.find('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });
            $productContainer.find('button.add-to-cart-global').attr('disabled', !enable);
        }
    });
}

integrationsCore.updateAvailability = function () {
    $('body').on('product:updateAvailability', function (e, response) {
        var $productContainer = response.$productContainer;
        // bundle individual products
        $productContainer.find('.product-availability')
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);
        //Quickview
        var $dialog = $productContainer.closest('.quick-view-dialog');
        if ($dialog.length) {
            if ($dialog.find('.product-availability').length) {
                // bundle all products
                var allAvailable = $dialog.find('.product-availability').toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $dialog.find('.product-availability').toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $dialog.find('.global-availability')
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $dialog.find('.global-availability .availability-msg').empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
                // Add an error message for mobile if the quantity selected is too high
                var isEditProduct = $dialog.find('.update-cart-product-global').length > 0;
                var isMobile = $(window).width() < 767;

                if(isEditProduct && isMobile && response.product.selectedQuantity > response.product.quantities.length) {
                    $dialog.find('.availability').removeClass('hidden-xs-down');
                    $dialog.find('.modal-footer').addClass('row');
                    modal.setModalBodyMaxHeight($dialog);
                }
            } else {
                // single product
                $dialog.find('.global-availability')
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
            //main PDP
        } else {
            if ($productContainer.find('.global-availability').length) {
                var allAvailable = $productContainer.find('.product-availability').toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $productContainer.find('.product-availability').toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $productContainer.find('.global-availability')
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $productContainer.find('.global-availability .availability-msg').empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            }

            var isBonusProduct = $productContainer.closest('.bonus-product-item').length > 0;
            if (response.product.showBIS && !response.product.available && !isBonusProduct) {
                $('.js-bis-container').removeClass('d-none');
                $('.prices-add-to-cart-actions .cart-and-ipay').addClass('d-none');
                var $bisContainer = $('.js-bis-container');
                var $notifyButton = $('.notify-back-in-stock');
             
                if (!isInViewport($notifyButton) || !isInViewport($bisContainer)) {
                    var windowHeight = $(window).height();
                    var divBottomPosition = $notifyButton.offset().top + $notifyButton.outerHeight() + 24;
                    var scrollTo = divBottomPosition - windowHeight;
                    $('html, body').animate({ scrollTop: scrollTo }, 500);
                }
            } else {
                $('.js-bis-container').addClass('d-none');
                $('.prices-add-to-cart-actions .cart-and-ipay').removeClass('d-none');
            }
        }

        // Low stock message.
        var $stockEl = $productContainer.find('.stock-indicator');
        var $stockMsg = $stockEl.find('.stock-message');
        if ('stockMessage' in response.product.availability && response.product.availability.outOfStock !== true) {
            $stockMsg.html(response.product.availability.stockMessage);
            $stockEl.addClass('visible');
            $stockEl.toggleClass('low-stock', !response.product.availability.outOfStock);
        } else {
            $stockEl.removeClass('visible');
            $stockMsg.empty();
        }
    });
}

function getBackInStock(pid) {
    var url = $('.js-bis-container').data('url');
    $.ajax({
        url: url,
        method: 'post',
        data: {pid: pid},
        success: function (resp) {
            $('.js-bis-container').empty().html(resp.template);
        }
    });
}

function isInViewport(element) {
    var $element = $(element);
    var elementTop = $element.offset().top;
    var elementBottom = elementTop + $element.outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
}

integrationsCore.backInStock = function () {
    // Check for BIS include and init
    if ($('.js-bis-container').length > 0) {
        // Trigger updates
        $('body').on('product:beforeAttributeSelect', function () {
            $('.js-bis-container').addClass('d-none');
        });
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if (!response.data.product.available) {
                var pid = response.data.product.id;
                getBackInStock(pid);
                response.container.find('button.add-to-cart, button.select-size').addClass('d-none');
            }
        });

        // Handle BIS form submit
        $('body').on('submit', '.js-bis-container .bis-signup-form', function(e) {
            e.preventDefault();
            if ($('#bis-consent').length > 0 && $('#bis-consent').is(':checked')) {
                $('#bis-consent').val(true);
            }
            var form = $(this);
            var url = form.attr('action');
            $.ajax({
                url: url,
                method: 'post',
                data: form.serialize(),
                success: function(resp) {
                    if (resp.success) {
                        // Hide form and display success message
                        $('.bis-signup-form').addClass('d-none');
                        $('.bis-signup-success').removeClass('d-none');
                    } else {
                        // Handle Error
                    }
                }
            });
        });

        // Init on load
        var pid = integrationsCore.methods.getPidValue($('.js-bis-container'));
        getBackInStock(pid);
    }
}

/**
 * Handling for anchored size chart (different from integration core trigger for .size-chart-launcher which launches modal)
 */
integrationsCore.sizeChartAnchor = function() {
    $('body').on('click', '.size-chart-anchor', function (e) {
        if ($(this).closest('.product-quickview').length === 0) {
            e.preventDefault();
            var scrollToElTop = $('#static-size-chart').offset().top;
            var headerHeight = $('.header-nav').height();
            $('html, body').stop().animate({
                'scrollTop': scrollToElTop - headerHeight
            });
        }
    });
}

/**
 * Overwrites the method from core cartridge.
 */
 integrationsCore.methods.attributeSelect = function (selectedValueUrl, $productContainer, isColorAttributeClicked = false) {
    var methods = this;
    var ajaxLoadTimeout;
    var spinnerTimerThreshold = $('.spinner-timer-threshold');
    if (selectedValueUrl) {
        if ($('.bundle-items .bundle-item').length > 0) {
            var paramJoiner = selectedValueUrl.indexOf('?') > -1 ? '&' : '?';
            selectedValueUrl = selectedValueUrl + paramJoiner + 'bundle=true';
        }
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            beforeSend : function() {
                ajaxLoadTimeout = setTimeout(function() {
                    $('body').trigger('product:beforeAttributeSelect',
                        { url: selectedValueUrl, container: $productContainer });
                }, spinnerTimerThreshold.data('spinner-threshold'));
            },
            success: function (data) {
                clearTimeout(ajaxLoadTimeout);
                data.resources.isColorAttributeClicked = isColorAttributeClicked;
                methods.handleVariantResponse(data, $productContainer);
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect',
                    {
                        data: data,
                        container: $productContainer,
                        isColorAttributeClicked: isColorAttributeClicked
                    });
                $.spinner().stop();

                var isQuickViewModal = $($productContainer).hasClass('product-quickview');
                if (!isQuickViewModal) {
                    updatePrimaryImageWrapper();
                } else {
                    var $viewDetailsButton = $productContainer.find('.quickview-viewdetails');
                    var viewDetailsButtonLink = $viewDetailsButton.attr('href');
                    // Replace existing view details button link with new selected product
                    viewDetailsButtonLink = viewDetailsButtonLink.replace(/([^\/]+)(?=\.html)/, data.product.id);
                    $viewDetailsButton.attr('href', viewDetailsButtonLink);
                }

                // Trigger Klaviyo event send for 'Viewed Product' when new color selected
                if (isColorAttributeClicked) {
                    var klaviyoViewedProduct = $productContainer.attr('data-klaviyo') + '&params=' + data.product.id;
                    $.ajax({
                        url: klaviyoViewedProduct,
                        type: 'GET',
                        success: function (data) {}
                    });
                }

                //Select size if only one available after selecting attributes
                if ($productContainer.find('.size-value').length == 1 && $productContainer.find('.size-value.selected').length == 0){
                    $productContainer.find('.size-value').trigger('click');
                }
            },
            error: function () {
                clearTimeout(ajaxLoadTimeout);
                $.spinner().stop();
            }
        });
    }
}

function updatePrimaryImageWrapper(){
    var wrappedImagesPresent = $('body .primary-images-main .image-wrapper img').length > 0 ? true : false;
    var noimagePresent = ($('body .primary-images-main img').length === 1 && $('body .primary-images-main img').attr('src').indexOf('noimage-') !== -1) ? true : false;
    var isQuickView = $('body').find('.modal.show .product-quickview').length !== 0;

    if (!noimagePresent && !window.isMobile() && !wrappedImagesPresent && !isQuickView){
        var $images = $('body .primary-images-main img');
        $($images).each(function() {
            var $image = $(this);
            $($image).wrap('<div class="image-wrapper zoomed-out"/>');
        });
    } else if (!noimagePresent && window.isMobile() && wrappedImagesPresent && !isQuickView) {
        var $images = $('body .primary-images-main .image-wrapper img');
        $($images).each(function() {
            var $image = $(this);
            $($image).unwrap();
        });
    } else if (noimagePresent){
        $('body .primary-images-main img').unwrap();
    }
}

$(window).on("resize", function() {
    updatePrimaryImageWrapper();
});

/**
 * Overwrites the method from rvw_autobahn_core cartridge.
 * After clicking select-bonus-product, click add-bonus-products to ATC
 */
integrationsCore.selectBonusProduct = function() {
    $(document).on('click', '.select-bonus-product', function () {
        $(".bonus-summary-products-container").empty();
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
        var totalQty = 0;
        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });
        totalQty += submittedQty;
        var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
        var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
        if (totalQty <= maxPids) {
            var selectedBonusProductHtml = ''
            + '<div class="selected-pid" '
            + 'data-pid="' + pid + '"'
            + 'data-qty="' + submittedQty + '"'
            + 'data-optionID="' + (optionID || '') + '"'
            + 'data-option-selected-value="' + (valueId || '') + '"'
            + '>'
            + '<div class="bonus-product-name">'
            + $choiceOfBonusProduct.find('.product-name').html()
            + '</div>'
            + '<div class="remove-bonus-product"></div>'
            + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
            // After selecting the bonus product item, add it to the cart
            $('#chooseBonusProductModal .add-bonus-products').click();
            // Update MiniCart to display bonus item
            var $bonusProductImage = $($choiceOfBonusProduct).find(".primary-images-main img").first().attr("src") || null;
            var $bonusProductName = $($choiceOfBonusProduct).find(".product-name").text() || null;
            var $swatches = $($choiceOfBonusProduct).find(".swatch .attribute:visible") || null;
            if ($bonusProductImage){
                $('.minicart-pd .product-image').attr('src', $bonusProductImage );
            }
            if ($bonusProductName){
                $('.minicart-pd .line-item-name').attr('href', '');
                $('.minicart-pd .line-item-name').text($bonusProductName);
                $('.minicart-pd .line-item-name').attr('title', $bonusProductName);
                $('.remove-product').remove();
                $('.bonus-product-button-wrapper').remove();
                $('.product-card-price-wrapper').remove();
            }
            if ($swatches){
                $('.product-card-text-wrapper .item-attributes .line-item-attributes:visible').remove();
                $($($swatches).get().reverse()).each(function(index, element){
                    if ($(element).find(".color").length){
                        var text = $(element).find(".color").text();
                        $('.product-card-text-wrapper .item-attributes').prepend('<p class=\"line-item-attributes\">' + text + '</p>');
                    } else {
                        var label = $(element).find(".non-input-label span").text();
                        var value = $(element).find(".swatch-tile-container:visible .selected").data("displayValue");
                        $('.product-card-text-wrapper .item-attributes').prepend('<p class=\"line-item-attributes\">' + label + ': ' + value + '</p>');
                    }
                });
            }
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }
    });
}

/**
 * Overwrites the method from rvw_autobahn_core cartridge.
 * Click add-bonus-products will update the minicart to display bonus product
 */
integrationsCore.addBonusProductsToCart = function() {
    $(document).on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');

        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var qtyOption =
                parseInt($(this)
                    .data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                    option = {};
                    option.optionId = $(this).data('optionid');
                    option.productId = $(this).data('pid');
                    option.selectedValueId = $(this).data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: $(this).data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('#chooseBonusProductModal').modal('hide');
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.minicart-pd .checkout-btn span').text('[' + data.totalQty+  ']')

                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('#chooseBonusProductModal').modal('hide');

                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').html(data.totalQty);
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }, 1500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

/**
 * Overwrites the method from rvw_autobahn_core cartridge.
 * Updates status of ATC button based on swatch selection
 */
integrationsCore.enableBonusProductSelection = function () {
    $('body').on('bonusproduct:updateSelectButton', function (e, response) {
        var selectedOptions = $('#chooseBonusProductModal span.swatch.selected').length;
        var availableOptions = $('#chooseBonusProductModal .attribute .swatch-tile-container').length;
        var disabled = false;
        if (selectedOptions !== availableOptions || !response.product.readyToOrder || !response.product.available){
            var disabled = true;
        }
        $('button.select-bonus-product', response.$productContainer).attr('disabled', disabled);
        var pid = response.product.id;
        $('button.select-bonus-product', response.$productContainer).data('pid', pid);
    });
}

module.exports = integrationsCore;
