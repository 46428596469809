/**
 * If lazy loading is enabled, adds lazy loading ability to footer 's social imgs,
 * otherwise, apply src image back.
 * @param {boolean} isLazyLoadEnabled whether lazy loading is enabled in config
 */
function footerImages(isLazyLoadEnabled) {
    var images = document.querySelectorAll('.content-asset[data-asset-id="footerSocial"] img');

    images.forEach(img => {
        if (isLazyLoadEnabled) {
            img.classList.add('lazyload');
        } else {
            img.src = img.dataset.src;
        }
    });
}

/*
* This method will immediately load the first six product tile images on a PLP,
* also remove all secondary image on mobile.
*
*/
function criticalProductTiles() {
    var images = document.querySelectorAll('img.lazyload');
    var index = 0;

    images.forEach(img => {
        if (img.classList.contains('product-tile-image-secondary')) {
            if (window.isMobile()) {
                img.remove();
            }
            return;
        }

        index++;
    });
}

/*
* Add support to lazyload background images.
*/
function lazyBackgrounds() {
    document.addEventListener('lazybeforeunveil', function(e){
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });
}

module.exports = {
    initLazyLoad: function() {
        var isLazyLoadEnabled = document.querySelector('.content-asset[data-asset-id="lazySizesEmbedScript"]');
        if (isLazyLoadEnabled) {
            criticalProductTiles();
            footerImages(isLazyLoadEnabled);
            // Dynamically import the LazySizes library
            var lazyConfigs = document.querySelector('.lazy-configs');
            var script = document.createElement('script');
            script.src = lazyConfigs.dataset.lazysizesUrl;
            document.body.appendChild(script);
            lazyBackgrounds();
        } else {
            footerImages(false);
        }
    },
    methods: {
        criticalProductTiles: criticalProductTiles,
        lazyBackgrounds: lazyBackgrounds
    }
};
