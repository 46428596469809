'use strict';

const second = 1000,
minute = second * 60,
hour = minute * 60,
day = hour * 24,
year = day * 365;

function updateTime(expirationTime, $currentTimer) {
    var currentLocalTime = new Date().getTime();
    var timeDifference = expirationTime - currentLocalTime;
    
    if (currentLocalTime < expirationTime) {
        $currentTimer.find('.countdown-value-days').text(Math.floor((timeDifference % (year)) / day));
        $currentTimer.find('.countdown-value-hours').text(Math.floor((timeDifference % (day)) / hour));
        $currentTimer.find('.countdown-value-minutes').text(Math.floor((timeDifference % (hour)) / minute));
        $currentTimer.find('.countdown-value-seconds').text(Math.floor((timeDifference % (minute)) / second));
        return true;
    } else {
        $currentTimer.closest('.countdown-container').addClass('d-none');
        return false;
    }
}

module.exports = () => {
    $(document).ready(() => {
        if ($('.countdown-timer').length !== 0){
            $('.countdown-timer').find('.countdown').each(function(){
                // Get current time values
                var expirationTime = Number($(this).data('expiration'));
                var $currentTimer = $(this);
                
                // If !expired, show countdown timer, update every second
                if (updateTime(expirationTime, $currentTimer)){
                    $currentTimer.closest('.countdown-container').addClass('time-shown');

                    // Update time every second
                    var countdownUpdater = setInterval(function(){
                        // If timer expires, kill this interval event
                        if (!(updateTime(expirationTime, $currentTimer))){
                            clearInterval(countdownUpdater);
                        }
                    }, second);
                }
            });
        }
    });
}