const clientSideValidation = require('./clientSideValidation');

const $body = $('body');

module.exports = {
    init: () => {
        $('header .js-login, header .js-register, .checkout-customer-block .js-forgot-password').click(function (e) {
            e.preventDefault();

            showModal($(this).data('url'));
        });

        $('#login-form').submit(bindSubmit);
        $('#register-form').submit(bindSubmit);
    },

    methods: {
        bindSubmit
    }
}

/**
 * Make AJAX call to url and display Modal
 * Adds Close Event Listeners to Modal, default behavior overridden
 * @param {string} url 
 * @returns {*}
 */
function showModal(url) {
    $.spinner().start();
    
    return $.ajax(url)
        .then((data) => {
            const $modal = $(data.html);
            $body.append($modal);
            const $form = $modal.find('form');

            if ($form.length) {
                // unbind existing invalid event handlers
                $('form input, form select, form textarea').off('invalid');

                // rebind invalid event handler to include the modal form
                clientSideValidation.invalid();
            }

            // Animate Modal Close
            $modal.on('mousedown', function(e) {
                // Backdrop or Close Button clicked
                if (e.target === this || $(e.target).hasClass('close')) {
                    $(e.target).on('mouseup', function(){
                        animateModalClose($modal);
                    });
                }
            })
            .on('keydown', function(e) {
                // ESC Key pressed
                if (e.key === 'Escape') {
                    animateModalClose($modal);
                }
            });

            // delete myself after im closed.
            $modal.on('hidden.bs.modal', function () {
                $modal.remove();
                $body.removeClass('modal-open');
            })
            .on('shown.bs.modal', function () {
                $body.addClass('modal-open');
            });

            if ($form.length) {
                // handle form submission
                $modal.find('form').submit(bindSubmit);
            }

            // init click event on alternate login/register button at bottom of modal
            $modal.on('click', '.js-login, .js-register, .js-forgot-password', function (e) {
                e.preventDefault();

                // Update the existing modal with new data
                updateModal($(this).data('url'), $modal);
            })

            // show modal
            $modal.modal('show');

            $.spinner().stop();
        });
}

/**
 * Update Content of current visible Modal
 * Make AJAX call to url and replace $modal body with response HTML
 * @param {string} url
 * @param {jQuery} $modal
 * @returns {*}
 */
function updateModal(url, $modal) {
    $.spinner().start();
    return $.ajax({
        url: url,
        data: {
            'modalShown': true
        }
      }).then((data) => {
        // Update Modal
        $modal.attr('id', data.modalID);
        $modal.find('.header-text').text(data.modalHeader);
        $modal.find('.modal-body').html(data.html);
        // Focus Modal so ESC close listener works
        $modal.trigger('focus');

        $modal.find('form').submit(bindSubmit);

        $.spinner().stop();
    });
}

/** 
 * Override Modal close behavior
 * Set right position to animate. Delay removing modal until after animation completes.
 * Reload page when closing modal after successful account creation.
 * @param {jQuery} $modal The modal DOM Element
 */
function animateModalClose($modal) {
    var modalWidth = $modal.find('.modal-content').width();
    var timeoutDuration = $modal.find('.modal-dialog').css('transition-duration').slice(0, -1) * 1000;
    $modal.find('.modal-dialog').css('right', -modalWidth);
    setTimeout(function() {
        $modal.siblings('.modal-backdrop').remove();
        $modal.modal('hide');
        $modal.remove();
    }, timeoutDuration);

    if ($modal.attr('id') === 'registerSuccessModal'){
        location.reload();
    }
}

/**
 * Form submit handler
 *
 * @param e
 * @returns {*}
 */
function bindSubmit (e) {
    e.preventDefault();

    // clear any previous errors
    $(this).find('.js-failure').addClass('d-none');
    $(this).find('.is-invalid').removeClass('is-invalid');

    // show the spinner
    $(this).find('.spinner-border').removeClass('d-none');

    return $.ajax({
        url: $(this).attr('action'),
        method: $(this).attr('method'),
        data: $(this).serialize()
    })
        .then((data) => {
            if (data.error && data.error.length) {
                const $alert = $(this).find('.js-failure');

                // show the error
                $alert.find('.js-message').text(data.error.join('\n'));
                $alert.removeClass('d-none');

                // close button listener
                $alert.find('.close-alert').on('click', function(){
                    $($alert).addClass('d-none');
                });

                // hide the spinner
                $(this).find('.spinner-border').addClass('d-none');

            } else if (data.fields) {
                // populate field errors
                for (const key in data.fields) {
                    const $input = $(this).find(`[name=${key}]`);

                    $input.next().text(data.fields[key]);
                    $input.addClass('is-invalid');
                }

                // hide the spinner
                $(this).find('.spinner-border').addClass('d-none');
            } else if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            } else if (data.modalUrl) {
                const $modal = $(this.closest('.modal'));
                updateModal(data.modalUrl, $modal);
            }
        });
}
