'use strict';

var SiteConstants = require('constants/SiteConstants');
var $header, $headerBanner, $headerNav, headerNavHeight;
var updateMiniCart = true;

$(document).ready(function() {
    $header = $('header');
    $headerBanner = $header.find('.header-banner');
    $headerNav = $header.find('.header-nav');
    headerNavHeight = !$headerBanner.hasClass('d-none') ? $headerNav.outerHeight() + $headerBanner.outerHeight() : $headerNav.outerHeight();
});

function setMinicartThresholdBarWidth(){
    $(".threshold-bar-progress").each(function() {
        var percentage = $(this).data("thresholdPercentage");
        $(this).width(percentage + '%');
    });
}

function toggleMinicart(status, isDrawer) {
    var $miniCart = $header.find('.minicart-pd');

    if ($('.minicart-backdrop').length === 0) {
        $('.minicart').prepend("<div class='minicart-backdrop d-none'/>");
    }

    if (status === 'open') {
        isDrawer && $('html, body').addClass('lock-scroll');
        $('body').addClass('minicart-open');
        $miniCart
            .removeClass('hide')
            .addClass('show')
            .attr('aria-hidden', 'false');

        $header.siblings().attr('aria-hidden', 'true');
        $('.minicart-backdrop').removeClass('d-none');
        $('html, body').addClass('lock-scroll');
    } else {
        $('body').removeClass('minicart-open');
        $miniCart
            .removeClass('show')
            .addClass('hide')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');

        if(isDrawer) {
            setTimeout(() => {
                $('html, body').removeClass('lock-scroll');
                $headerNav.addClass('transparent-nav');
            }, SiteConstants.TransitionSpeed);
        }
        $('.minicart-backdrop').addClass('d-none');
        $('html, body').removeClass('lock-scroll');
    }
};


module.exports = () => {
    const isMobile = window.isMobile();
    const $minicart = $('.minicart');

    $minicart.on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $minicart.on('click', '.minicart-link', () => {
        var url = $('.minicart').data('cart-url');
        window.location.href=url;
    });

    $minicart.on('click', '.minicart-close', event => {
        event.stopPropagation();
        toggleMinicart('close', isMobile);
    });

    $('body').on('click', '.minicart-backdrop', function() {
        toggleMinicart('close', isMobile);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function() {
        toggleMinicart('close', isMobile);
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', () => {
        updateMiniCart = true;
        if (isMobile) {
            $('.minicart-link').trigger('focusin');
        }
    });

    $('body').on('minicart:loaded', (event, minicart) => {
        setMinicartThresholdBarWidth();
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
    });

    $('body').on('click', '.cart .bonus-product-button', function (e) {
        if ($(".modal#chooseBonusProductModal").length === 0) {
            $.spinner().start();
            $(this).addClass('launched-modal');
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('bonusproduct:edit', data);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
};
