'use strict';

$('body').on('mouseleave', '.custom-tooltip', function(){
    var $tooltip = $(this);
    $tooltip.removeClass('visible');
});

$('body').on('mouseenter', '.custom-tooltip', function(){
    var $tooltip = $(this);
    var $content = $(this).find('.custom-tooltip-content');
    var windowWidth = $(window).width();

    var containerPadding = Number($tooltip.closest('.container').css('padding-left').replace("px",""));
    if (containerPadding === 0){
        var SiteConstants = require('constants/SiteConstants');
        var xlBreakpoint = SiteConstants.BreakpointSizes.xl;
        containerPadding = windowWidth > xlBreakpoint ? 48 : 24;
    }

    var overflowedLeftSide = $content.offset().left < 0;
    var overflowedRightSide = (windowWidth  - ($content.offset().left + $content.outerWidth())) < 0;
    var overflowedBoth = $content.outerWidth() + (2 * containerPadding) > windowWidth;

    // If tooltip off-screen, repostion arrow, set new content position
    if (overflowedBoth){
        $tooltip.addClass('repositioned');
        $content.css('max-width', windowWidth - (2 * containerPadding));
        var newPosition = $content.position().left - $content.offset().left + ($content.outerWidth() / 2) + containerPadding;
        $content.css('left', newPosition);
    } else if (overflowedLeftSide && !overflowedRightSide){
        $tooltip.addClass('repositioned');
        var newPosition = $content.position().left - $content.offset().left + ($content.outerWidth() / 2) + containerPadding;
        $content.css('left', newPosition);
    } else if (!overflowedLeftSide && overflowedRightSide){
        $tooltip.addClass('repositioned');
        var currentLeft = Number($content.css('left').replace("px",""));
        var newPosition = currentLeft - $content.offset().left + windowWidth - $content.outerWidth() - containerPadding;
        $content.css('left', newPosition);
    }

    $tooltip.addClass('visible');
});

function getTooltipTitle() {
    return $(this).find('.tooltip');
}

function enableTooltipsAndPopovers() {
    // Initialize tooltips. This also replaces the SFRA custom tooltip with the Bootstrap one
    // "trigger" is set to manual in order to add functionality to allow for tooltip to stay open on hover
    $('[data-toggle="tooltip"], .info-icon').tooltip({
        title: getTooltipTitle,
        trigger: 'manual',
        boundary: document.body, // this prevents incorrect tooltip position due to parent container is a table or has overflow
        sanitize: false // prevents removal of inline styles in tooltip content markup
    });
    
    $('body').on('mouseenter', '[data-toggle="tooltip"]', buttonEnterEvent => {
        var $button = $(buttonEnterEvent.target).closest('[data-toggle="tooltip"]');
        var showDelay = $button.data('delay') && $button.data('delay').show || 0;
        var hideDelay = $button.data('delay') && $button.data('delay').hide || 0;

        setTimeout(() => {
            $button.tooltip('show');
        }, showDelay);

        $('body').on('mouseleave', '.tooltip', tooltipLeaveEvent => {
            setTimeout(() => {
                $(tooltipLeaveEvent.target).closest('.tooltip').tooltip('hide');
            }, hideDelay);
        });
    }).on('mouseleave', '[data-toggle="tooltip"]', buttonLeaveEvent => {
        var $button = $(buttonLeaveEvent.target).closest('[data-toggle="tooltip"]');
        var hideDelay = $button.data('delay') && $button.data('delay').hide || 0;

        setTimeout(() => {
            if (!$('.tooltip:hover').length) {
                $(buttonLeaveEvent.target).tooltip('hide');
            }
        }, hideDelay);
    });

    // Enables Bootstrap Popovers, which rely on the Tooltip library and provide extra functionality
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'focus',
        sanitize: false // prevents removal of inline styles in tooltip content markup
    });
}

module.exports = function() {
    enableTooltipsAndPopovers();

    $('body').on('tooltip:init', enableTooltipsAndPopovers);
};
