'use strict';

var einsteinUtils = require('./einsteinUtils');
var productTile = require('./productTile');
var abSlider = require('./slider');

module.exports = {};

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 * @param {Object} e jquery event
 * @returns {Object} jquery targets
 */
module.exports.loadRecommendations = function (e) {
    var $context = $(this);
    var $recommendationTargets = $context.hasClass('.einstein-data') ? $context : $context.find('.einstein-data');

    return $recommendationTargets.each(function () {
        var $recTrigger = $(this);
        var data = $recTrigger.data() || {};
        var asyncUrl = data.url;
        var recommender = $(this).data('recommender');

        einsteinUtils.getRecommendations(data, function (einsteinResponse) {
            var response = einsteinUtils.interpretResponse(data, einsteinResponse);

            // Initialize Einstein Recommendation tracking event parameters
            var cq_params = cq_params || {};
            cq_params.products = [];

            // Add replace content URL
            $.each(response, function (i, rec) {
                if (!rec || !rec.id) return null;

                // Push Products to Einstein Recommendation tracking event
                cq_params.products.push({
                    id: rec.id,
                    type: 'base'
                });

                var pidURL = asyncUrl;
                pidURL += (pidURL.indexOf('?') === -1 ? '?' : '&') + 'pid=' + rec.id;
                $recTrigger.find('.product[data-pid="placeholder"]:not([data-replace-content])')
                    .first()
                    .attr('data-replace-content', pidURL); //Must be attr to allow productTile.js async selector rule to catch recommendation
            });

            // Remove empty slides
            $recTrigger.find('.slide:has(.product[data-pid="placeholder"]:not([data-replace-content]))').remove();

            // Prepare Slider Container
            var $sliderContainer = $recTrigger.closest('.slider-container').removeClass('delay-init');

            // Initialize sliders
            abSlider.initializeSliders($sliderContainer.parent());

            // Trigger ASYNC update
            productTile.init($recTrigger.find('.product'));

            // Build parameters for Einstein tracking event
            cq_params = einsteinUtils.setParameters(cq_params, recommender);

            // Send viewReco Einstein tracking event for Reports
            window.CQuotient.sendActivity(window.CQuotient.clientId, 'viewReco', cq_params);
        });
    });
}

module.exports.init = function (context) {
    // Re-triggerable dom event
    $(document).off('load.einstein')
        .on('load.einstein', module.exports.loadRecommendations);

    // Send clickReco Einstein tracking event for Reports
    $(document).on('click', '.einstein-data .product', function(){
        var cq_params = cq_params || {};
        var recommender = $(this).closest('.einstein-data').data('recommender');
        cq_params.products = [];
        cq_params.products.push({
            id: $(this).data('pid'),
            type: 'base'
        });
        cq_params = einsteinUtils.setParameters(cq_params, recommender);
        window.CQuotient.sendActivity(window.CQuotient.clientId, 'clickReco', cq_params);
    });

    context = context && 'trigger' in context ? context : $(document);

    return context.triggerHandler('load.einstein');
};
