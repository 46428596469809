'use strict';

function swatchSwitch(el) {
    var $swatch = el;
    var $tileParent = $swatch.parents('.product-tile');
    var pid = $tileParent.attr('data-pid');
    var $tileImage = $tileParent.find('.product-tile-image');
    var $tileImageHover = $tileParent.find('.product-tile-image-secondary');
    var $tilePriceSpan = $tileParent.find('.price span').first();
    var listPrice = $swatch.attr('data-color-list-price').trim();
    var salePrice = $swatch.attr('data-color-sale-price').trim();
    var strikethrough;
    var salesPrice;

    $tileParent.find('.swatch.selected').removeClass('selected');
    $swatch.addClass('selected');

    //Replace main image and secondary image
    if($swatch.data('hover-image') && $swatch.data('hover-image') != '') {
        var swatchImageUrl = $swatch.data('hover-image');
        $tileImage.attr('src', swatchImageUrl);

        if($tileImageHover.length > 0 && $swatch.data('hover-image-secondary')) {
            var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
            $tileImage.data('alt-img-url', swatchSecondaryImageUrl);
            $tileImageHover.attr('src', swatchSecondaryImageUrl);
        }
    }

    //Replace default price with swatch price(s)
    if (salePrice.length && listPrice.length) {
        strikethrough = '<del><span class="strike-through list"><span class="value" content="'+listPrice+'"><span class="sr-only">Price reduced from</span>'+listPrice+'<span class="sr-only">to</span></span></span></del>';
        salesPrice = '<span class="sales"><span class="value" content="'+salePrice+'">'+salePrice+'</span></span>';
        $tilePriceSpan.empty();
        $tilePriceSpan.append(strikethrough);
        $tilePriceSpan.append(salesPrice);
    } else if ((!salePrice && listPrice.length) || (salePrice.length && !listPrice)) {
        var finalPrice = !salePrice && listPrice.length ? listPrice : salePrice;
        salesPrice = '<span class="sales"><span class="value" content="'+finalPrice+'">'+finalPrice+'</span></span>';
        $tilePriceSpan.empty();
        $tilePriceSpan.append(salesPrice);
    }

    //replace main link and Quickview link
    var swatchLink = $swatch.parents('.swatch-link');
    var splitSwatchLink = swatchLink.attr('href').split('?');
    var swatchLinkAttributes = splitSwatchLink[1];

    //main Image Link
    var mainImageLink = $tileParent.find('.product-tile-image-link');
    var mainImageLinkSplit = mainImageLink.attr('href').split('?');
    mainImageLink.attr('href', mainImageLinkSplit[0] + '?' + swatchLinkAttributes);

    //main Quickview Link
    var mainQuickViewLink = $tileParent.find('a.quickview');
    if (mainQuickViewLink.length) {
        var mainQuickViewLinkSplit = mainQuickViewLink.attr('href').split('?');
        //this is assuming that every swatch link will not contain a pid in the attributes
        var mainQuickViewLinkUrl = mainQuickViewLink.attr('href');
        var ESWAttributes = String(mainQuickViewLinkUrl).includes('EShopWorld-Cache') ? 'remoteIncludeUrl=Product-ShowQuickView&ajax=true&' : '';
        mainQuickViewLink.attr('href',  mainQuickViewLinkSplit[0] + '?' + ESWAttributes + 'pid=' + pid + '&' + swatchLinkAttributes);
    }

    //main Wishlist Link
    if ($swatch.attr('data-wishlistpid')) {
        var mainWishlistLink = $tileParent.find('a.wishlist');
        if (mainWishlistLink.length) {
            mainWishlistLink.attr('data-wishlistpid', $swatch.attr('data-wishlistpid'));

            //Make heart icon accurate
            var wishlist = require('core/wishlist/wishlist.js');
            wishlist.updateWishlistLinkData(mainWishlistLink);
        }
    }

    //main Text Link
    var mainTextLink = $tileParent.find('.pdp-link a.link');
    if (mainTextLink.length) {
        var mainTextLinkSplit = mainTextLink.attr('href').split('?');
        mainTextLink.attr('href', mainTextLinkSplit[0] + '?' + swatchLinkAttributes);
    }
}

function hijackSwatchClick() {
    if (window.isMobile()) {
        $('body').on('click.swatch-link', '.product-tile .swatch-link', function(e) {
            e.preventDefault();
            var $swatch = $(this).find('.swatch');
            swatchSwitch($swatch);
        })
    }
    else {
        $('body').off('click.swatch-link');
    }
}

$('body').on('mouseenter', '.product-tile .swatch', function(e) {
    var $swatch = $(this);
    swatchSwitch($swatch);
});

$(window).on('resize',function() {
    hijackSwatchClick();
});

hijackSwatchClick();
