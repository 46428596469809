'use strict';

/* global $, ArrayBuffer, Uint8Array, URL */

/**
 * Check if browser is safari
 */
 function isSafari() {
    return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1;
}

/**
 * Initialize the download button
 */
function initDownloadButton() {
    $('.return-label-download').on('click', function (e) {
        e.preventDefault();
        var pdf = $(this).attr('href');
        pdf = pdf.replace('data:application/pdf;base64,', '');
        var binary = atob(pdf.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // Create the blob object with content-type "application/pdf"
        var blob = new Blob([view], { type: 'application/pdf' });
        var url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    });
}

function init() {
    $(document).ready(function () {
        if (isSafari()) {
            initDownloadButton();
        }
    });
}

module.exports = {
    init: init
}