'use strict';
 
module.exports = {
    init: function() {
        $("#blogFilter").on("change", function() {
            var selectedValue = $(this).val();
            window.location.href = selectedValue;
        });
        // Prevent dragging Slide from clicking blog link
        $('body').on('mousedown', '.blog .enhanced-slider-container .slide', function(e){
            e.preventDefault();
        }).on('mouseup', '.blog .enhanced-slider-container .slide', function(e){
            e.preventDefault();
        });
    }
}