'use strict';

var core = require('core/product/quickView');
var modal = require('core/components/modal');
const { updateAddAllToCart, selectSizeCTA } = require('./detail');

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
 function fillModalElement(selectedValueUrl) {
    var $modal = $('#quickViewModal');
    $modal.spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);
            //$modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $modal.find('.full-pdp-link').attr('href', data.productUrl);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find('#quickViewModal').modal('show');

            if (data.dialogTitle) {
                $modal.find('.modal-header').prepend('<div class="modal-title">' + data.dialogTitle + '</div>')
            }

            if (data.product.productType === 'set') {
                updateAddAllToCart();
            }

            selectSizeCTA();

            $('body').trigger('modal:loaded', $('#quickViewModal')); // sending custom event for scroll body sizing
            $('body').trigger('quickview:ready', $('#quickViewModal')); // sending custom event for slider init
            $('body').trigger('tooltip:init');
            setNoSize();

            if ($('#quickViewModal .size-value').length == 1 && $('#quickViewModal .size-value.selected').length == 0){
                $('#quickViewModal .size-value').trigger('click');
            }

            window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
            window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });

            $.spinner().stop();
        },
        error: function() {
            $.spinner().stop();
        }
    });
}

module.exports = {
    focusQuickview: function() {
        $('body').on('shown.bs.modal', '#quickViewModal', function() {
            $('#quickViewModal').siblings().attr('aria-hidden', 'false');
            $('#quickViewModal .close').focus();
        });
    },
    trapQuickviewFocus: function() {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.qv-product-edit-add',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    showSpinner: function() {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal').spinner().start();
        });
    },
    hideDialog: function() {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
            $('#quickViewModal').siblings().attr('aria-hidden', 'true');
        });
    },
    beforeUpdateAttribute: function() {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show').spinner().start();
        });
    }
};

core.selectSizeCTA = function() {
    $('body').on('click', 'button.select-size', function(e){
        $('.size-error').removeClass('d-none');
    });

    $('body').on('click', '.size-value:not(.unselectable)', function(e) {
        if (!$('.size-error').hasClass('d-none')){
            $('.size-error').addClass('d-none');
        }
        $(e.target).closest('.modal-body').find('.attribute.quantity').removeClass('d-sm-none');
        $(e.target).closest('.modal-body').find('.quantity-select').removeClass('d-none');
    });

    $('body').on('click', '.size-value', function(e){
        let $productContainer = $(e.target).closest('.product-detail');
        let isQuickViewModal = $($productContainer).hasClass('product-quickview');
        if (isQuickViewModal) {
            $productContainer.find('button.select-size').addClass('d-none');
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, passedObj) {
        if (passedObj.isColorAttributeClicked) {
            let $addToCart = passedObj.container.find('.add-to-cart-global');

            if (!$addToCart.hasClass('d-none')) {
                $addToCart.addClass('d-none');
                passedObj.container.find('.apple-pay-container').addClass('d-none');
                passedObj.container.find('button.select-size').removeClass('d-none');
            }
        }
    });
};

function setNoSize(){
    if (!$('button.select-size').hasClass('d-none')){
        $('.color-attribute').each(function(){
            $(this).attr('data-url',$(this).attr('data-url') + '&noSize=true');
        });
    }
}


core.showQuickview = function() {
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        modal.getModalHtmlElement('quickViewModal', 'quick-view-dialog');
        fillModalElement(selectedValueUrl);
    });
}

module.exports = core;
