'use strict';

var SiteConstants = require('constants/SiteConstants');

module.exports = () => {
    if ($('.enhanced-slider-container').length > 0) {
        // Variable declaration used only for setting up event listener
        // Use getClosestSlider() for accessing closest slider
        var $slider = $('.enhanced-slider-container .experience-slides');
        var $btnPrevious = $('.enhanced-slider-container .btn-slider-left');
        var $btnNext = $('.enhanced-slider-container .btn-slider-right');

        const animationTime = 300 // 0.3 seconds
        var slideGap = $($slider).css('column-gap').slice(0, -2);
        var startX = 0;
        var distanceAdded = 0;
        var scrollSnapTimeoutEvent = null;

        /**
         * Re-Initialize Slider when # of slides changes
         * Instance of DOM MutationObserve.
         */
        var numSlidesChanged = new MutationObserver(e => {
            var currentSlider = e[0].target;
            var maxSlides = $(currentSlider).children().length;
            $(currentSlider).data('maxSlides', maxSlides);
            updateNavBreakpoint(currentSlider);
            disableDragging(currentSlider);
            updateScrollPositions(currentSlider);
            updateButtonState(currentSlider);
        });

        // Initialize Sliders
        $($slider).each(function(index, currentSlider){
            $(currentSlider).children().first().addClass('active');
            $(currentSlider).data('slideIndex', 0);
            var maxSlides = $(currentSlider).children().length;
            $(currentSlider).data('maxSlides', maxSlides);
            if ($(currentSlider).find('.slide').length !== 0){
                updateNavBreakpoint(currentSlider);
            }
            observeSlideChanges(currentSlider);
            disableDragging(currentSlider);
            updateScrollPositions(currentSlider);
            updateButtonState(currentSlider);
        });

        // Re-Initialize Sliders on Resize
        $(window).resize(function() {
            $($slider).each(function(index, currentSlider){
                updateScrollPositions(currentSlider);
                updateButtonState(currentSlider);
            });
        });

        $($slider).on('mousedown', function(e){
            var currentSlider = getClosestSlider(e);
            $(currentSlider).addClass('pressed');
            startX = e.clientX;
        });

        $($slider).on('mouseleave', function(e){
            var currentSlider = getClosestSlider(e);
            $(currentSlider).removeClass('pressed');
            $(currentSlider).removeClass('slider-moving');
        });
        
        $($slider).on('mouseup', function(e){
            var currentSlider = getClosestSlider(e);
            $(currentSlider).removeClass('pressed');
            $(currentSlider).removeClass('slider-moving');
            setScrollLeft(currentSlider);
            distanceAdded = 0;
        });

        $($slider).on('mousemove', function(e){
            var currentSlider = getClosestSlider(e);
            if ($(currentSlider).hasClass('pressed')) {
                // Debounce to allow click event with minor dragging movement
                if (Math.abs(distanceAdded) > 10) {
                    $(currentSlider).addClass('slider-moving');
                    $(currentSlider).addClass('no-scroll-snap');
                }
                // Update scroll position
                var distanceAway = startX - e.clientX;
                var currentScrollPosition = $(currentSlider).scrollLeft();
                $(currentSlider).scrollLeft(currentScrollPosition + distanceAway - distanceAdded);
                distanceAdded = distanceAway;
            }
        });

        $($slider).on('scroll', function(e){
            var currentSlider = getClosestSlider(e);
            setActiveSlide(currentSlider);
            updateButtonState(currentSlider);
        });

        /**
         * Updates whether previous/next buttons are disabled on the current slider
         * @param {jQuery Object} currentSlider The slider container
         */
        function updateButtonState(currentSlider){
            var btnPrev = $(currentSlider).closest('.enhanced-slider-container').find('.btn-slider-left');
            var btnNext = $(currentSlider).closest('.enhanced-slider-container').find('.btn-slider-right');
            var currentSlideIndex = $(currentSlider).data('slideIndex');
            var scrollPositions = $(currentSlider).data('scrollPositions');
            if (currentSlideIndex >= scrollPositions){
                $(btnPrev).removeClass('disabled');
                $(btnNext).addClass('disabled');
            } else if (currentSlideIndex === 0){
                $(btnPrev).addClass('disabled');
                $(btnNext).removeClass('disabled');
            } else {
                $(btnPrev).removeClass('disabled');
                $(btnNext).removeClass('disabled');
            }
        }

        /**
         * Set left most slide on slider to active
         * @param {jQuery Object} currentSlider The slider container
         */
        function setActiveSlide(currentSlider){
            var activeSlideIndex = returnActiveSlide(currentSlider);
            var currentSlideIndex = $(currentSlider).data('slideIndex');
            if (currentSlideIndex != activeSlideIndex){
                $(currentSlider).find('.active').removeClass('active');
                $($(currentSlider).children().get(activeSlideIndex)).addClass('active');
            }
            currentSlideIndex = activeSlideIndex;
            $(currentSlider).data('slideIndex', currentSlideIndex);
        }

        /**
         * Calculates the left-most slider to set active status on
         * @param {jQuery Object} currentSlider The slider container
         * @returns {Number} Index of the left most slide
         */
        function returnActiveSlide(currentSlider){
            var smallestOffset = null;
            var leftMostSlide = null;
            $(currentSlider).children().each(function(i, element){
                if (smallestOffset !== null){
                    var currentOffset = Math.abs($(element).offset().left);
                    if (currentOffset < smallestOffset){
                        smallestOffset = currentOffset;
                        leftMostSlide = i;
                    }
                } else {
                    smallestOffset = Math.abs($(element).offset().left);
                    leftMostSlide = i;
                }
            });
            if (smallestOffset !== null && leftMostSlide !== null){
                return leftMostSlide;
            }
        }

        /**
         * Only remove no-scroll-snap class when all animations are finished
         * @param {jQuery Object} currentSlider The slider container
         */
        function scrollSnapTimeout(currentSlider){
            if (scrollSnapTimeoutEvent) {
                clearTimeout(scrollSnapTimeoutEvent); //cancel the previous timer.
                scrollSnapTimeoutEvent = null;
            }
            scrollSnapTimeoutEvent = setTimeout(function(){
                $(currentSlider).removeClass('no-scroll-snap');
            }, animationTime);
        }

        /**
         * Set the scroll bar value based on the new active slide
         * @param {jQuery Object} currentSlider The slider container
         */
        function setScrollLeft(currentSlider){
            $(currentSlider).addClass('no-scroll-snap');
            var slideWidth = $(currentSlider).children().first().width();
            var currentSlideIndex = $(currentSlider).data('slideIndex');
            var newScrollLeft = (currentSlideIndex * slideWidth) + (currentSlideIndex * slideGap);
            scrollSnapTimeout(currentSlider);
            $(currentSlider).animate({scrollLeft: newScrollLeft}, {duration: animationTime, queue: false});
        }

        /**
         * Update display class of navigation buttons
         * @param {jQuery Object} currentSlider The current slider container
         */
        function updateNavBreakpoint(currentSlider){
            var maxSlides = $(currentSlider).data('maxSlides');
            var mdSlideCount = Math.trunc($(currentSlider).closest('.enhanced-slider-container').attr('data-tablet-slides'));
            var lgSlideCount = Math.trunc($(currentSlider).closest('.enhanced-slider-container').attr('data-desktop-slides'));

            var btnPrev = $(currentSlider).closest('.enhanced-slider-container').find('.btn-slider-left');
            var btnNext = $(currentSlider).closest('.enhanced-slider-container').find('.btn-slider-right');
            $(btnPrev).removeClass('d-lg-none d-md-none d-sm-none d-none');
            $(btnNext).removeClass('d-lg-none d-md-none d-sm-none d-none');

            if (maxSlides <= 4){
                var hiddenBreakpoints = '';
                if (lgSlideCount === 2 && mdSlideCount === 3){
                    // Desktop - Show Less Slides
                    hiddenBreakpoints = maxSlides === 3 ? 'd-md-none d-lg-block' : hiddenBreakpoints;
                } else if (lgSlideCount === 4 && mdSlideCount === 2){
                    // Tablet - Show Less Slides
                    hiddenBreakpoints = maxSlides === 4 ? 'd-md-block d-lg-none' : hiddenBreakpoints;
                    hiddenBreakpoints = maxSlides === 3 ? 'd-md-block d-lg-none' : hiddenBreakpoints;
                } else if (lgSlideCount === 2 && mdSlideCount === 2){
                    // Both - Show Less Slides
                    hiddenBreakpoints = '';
                } else {
                    hiddenBreakpoints = maxSlides === 4 ? 'd-lg-none' : hiddenBreakpoints;
                    hiddenBreakpoints = maxSlides === 3 ? 'd-md-none' : hiddenBreakpoints;
                }
                hiddenBreakpoints = maxSlides === 2 ? 'd-sm-none' : hiddenBreakpoints;
                hiddenBreakpoints = maxSlides <= 1 ? 'd-none' : hiddenBreakpoints;

                $(btnPrev).addClass(hiddenBreakpoints);
                $(btnNext).addClass(hiddenBreakpoints);
            }
        }

        /**
         * Update properties related to scroll positions
         * @param {jQuery Object} currentSlider The current slider container
         */
        function updateScrollPositions(currentSlider){
            var maxSlides = $(currentSlider).data('maxSlides');
            var scrollPositions = null;
            var windowWidth = $(window).width();
            var smallBreakpoint = SiteConstants.BreakpointSizes.sm;
            var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
            var largeBreakpoint = SiteConstants.BreakpointSizes.lg;
            
            var xsmallView = windowWidth < smallBreakpoint;
            var smallView = windowWidth >= smallBreakpoint && windowWidth < mediumBreakpoint;
            var mediumView = windowWidth >= mediumBreakpoint && windowWidth < largeBreakpoint;
            var largeView = windowWidth >= largeBreakpoint;

            var tabletSlideNum = $(currentSlider).closest('.enhanced-slider-container').attr('data-tablet-slides');
            var desktopSlideNum = $(currentSlider).closest('.enhanced-slider-container').attr('data-desktop-slides');

            if (xsmallView){
                scrollPositions = maxSlides - 1;
            } else if (smallView){
                scrollPositions = maxSlides - 2;
            } else if (mediumView){
                scrollPositions = maxSlides - tabletSlideNum;
            } else if (largeView){
                scrollPositions = maxSlides - desktopSlideNum;
            }
            if (scrollPositions < 0){
                scrollPositions = 0;
            }

            $(currentSlider).data('scrollPositions', scrollPositions);
        }

        /**
         * Get closest slider to clicked element
         * @param {jQuery Object} e The clicked element
         * @returns {jQuery Object} The closest slider
         */
        function getClosestSlider(e){
            return $(e.currentTarget).closest('.enhanced-slider-container').find('.experience-slides');
        }

        /**
         * Disables the draggable attribute on all images and links on a slider
         * @param {jQuery Object} currentSlider The current slider
         */
        function disableDragging(currentSlider){
            var images = $(currentSlider).find('img');
            var links = $(currentSlider).find('a');
            $(links).each(function(index, link){
                $(link).attr('draggable', false);
            });
            $(images).each(function(index, image){
                $(image).attr('draggable', false);
            });
        }
    
        /**
         * Sets up a DOM MutationObserver on all sliders.
         * Used by numSlidesChanged when # of slides changes.
         * @param {jQuery Object} currentSlider The slider container
         */
        function observeSlideChanges(currentSlider){
            numSlidesChanged.observe(currentSlider, {
                attributes: false, 
                childList: true, 
                characterData: false
            });
        }

        $($btnPrevious).on('click', function(e){
            var currentSlider = getClosestSlider(e);
            var currentSlideIndex = $(currentSlider).data('slideIndex');
            if (currentSlideIndex !== 0){
                currentSlideIndex--;
                $(currentSlider).data('slideIndex', currentSlideIndex);
                setScrollLeft(currentSlider);
            }
        });

        $($btnNext).on('click', function(e){
            var currentSlider = getClosestSlider(e);
            var currentSlideIndex = $(currentSlider).data('slideIndex');
            var maxSlides = $(e).data('maxSlides');
            if (currentSlideIndex !== (maxSlides - 1)){
                currentSlideIndex++;
                $(currentSlider).data('slideIndex', currentSlideIndex);
                setScrollLeft(currentSlider);
            }
        });
    }
};