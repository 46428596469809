var main = require('integrations/main');

main.baseFiles.bonusProduct = require('./components/bonusProduct');
main.baseFiles.enhancedSlider = require('./components/enhancedSlider');
main.baseFiles.lazyLoad = require('./components/lazyLoad');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.header = require('./components/header');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.search = require('./components/search');
main.baseFiles.countrySelector = require('./components/countrySelector');
main.baseFiles.geoRedirect = require('./components/geoRedirect');
main.baseFiles.paymentTabs = require('./components/paymentTabs');
main.baseFiles.zoom = require('./product/zoom');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.base = require('./product/base');
main.baseFiles.cart = require('./cart');
main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.auth = require('./components/auth');
main.baseFiles.toolTip = require('./components/toolTip');
main.baseFiles.pageDesigner = require('./pageDesigner');
main.baseFiles.mobilePdfDownload = require('./components/mobilePdfDownload');
main.baseFiles.blog = require('./blog');
main.baseFiles.header = require('./header');
main.baseFiles.preloader = require('./components/preloader');
main.baseFiles.countdown = require('./components/countdown');
main.baseFiles.loyalty = require('./components/loyalty');
module.exports = main;
