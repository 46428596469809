'use strict';

// These values should always match the variables set in _variables.scss

module.exports.BreakpointSizes = {
    'sm': 544,
    'md': 769,
    'lg': 1024,
    'xl': 1440
};

module.exports.TransitionSpeed = 200;

module.exports.ComponentAnimationDelay = 0;

module.exports.Spacer = 12;

module.exports.placeholderImagePaths = {
    'thumbnail': '/gray.png',
    'imageMissing': 'header/placeholder.png'
}

module.exports.defaultMapMarker = {
    'color': 'white',
    'backgroundImage': '/images/icons/map-marker-default.svg'
}

module.exports.defaultStoreType = {
    'id': 0,
    'displayValue': 'Retail',
    'value': 'retail'
}
