'use strict';

module.exports = () => {
    $('body').on('click', '#chooseBonusProductModal .choice-of-bonus-product .select-product', function() {
        var pid = $(this).data("pid");
        $('#chooseBonusProductModal .choice-of-bonus-product').each(function(index, element){
            if ($(element).data("pid") !== pid){
                $(element).remove();
            } else {
                $(element).removeClass("d-none");
            }
        });
        $('#chooseBonusProductModal .attribute').each(function(index, element){
            var $swatch = $(element).find('.swatch.selectable');
            var selected = $(element).find('.swatch.selected').length;
            if ($($swatch).length === 1 && !selected){
                $($swatch).click();
            }
        });
    });

    // Additional listener for single size products with hidden OOS colors
    $('body').on('product:afterAttributeSelect', function() {
        $('#chooseBonusProductModal .attribute').each(function(index, element){
            var $swatch = $(element).find('.swatch.selectable');
            var selected = $(element).find('.swatch.selected').length;
            if ($($swatch).length === 1 && !selected){
                $($swatch).click();
            }
        });
    });
};
