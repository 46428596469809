'use strict';

module.exports = {
    selectors: {
        processing: '.processing'
    },
    init: function ($context = $('.product[data-replace-content], .product [data-replace-content]')) {
        var scope = this;

        $context
        .filter(':not(' + scope.selectors.processing + ')')
        .each(function (i, replace) {
            var $target = $(replace);
            var asyncUrl = $target.data('replace-content') || $target.attr('data-replace-content');
            var deferment = $.Deferred();

            if (['', null, undefined].indexOf(asyncUrl) === -1) {
                // Only track deferment if url is requestable
                $target.data('deferment', deferment);

                // Seperate deferment-ness of ajax request from actual deferment to allow failures to progress current promise group
                $target.data('$xhr', $.ajax({
                    url: asyncUrl,
                    dataType: 'html',
                    timeout: 60000,
                    context: $target,
                    beforeSend: function () {
                        this.addClass(scope.selectors.processing.replace(/\./gi,' '));
                    },
                    success: function (data) {
                        // Per AJAX response populate the slider array
                        this.replaceWith(data);
                    },
                    complete: function () {
                        deferment.resolve();
                    },
                    error: function() {
                        if ($('.enhanced-slider-container').find(this).length > 0){
                            $(this).parent('.slide').remove();
                        }
                    }
                }));
            }
        });
    }
}
